import { graphql } from 'gatsby';
import React, { ReactNode } from 'react';
import Header from '../../components/HeaderDynamicPages/Header';
import Layout from '../../components/Layout/Layout';
import {
    ContactDetailsSection,
    ContactDetailsSectionLabel,
    ContactDetailsSectionText,
    ContactDetailsWrapper,
    ContactImage,
    ContactWrapper,
    RegionContainer,
    RegionDescription,
    RegionTitle,
} from './regionStyle';
import placeHolder from '../../static/images/placeholder.jpg';
import { urlForImage } from '../../utils/urlForImage';
import Alternate from '../../components/Seo/Alternate';

const Region = ({ pageContext }) => {
    const { region, languages } = pageContext;

    const displayContactDetails = (): ReactNode => (
        <ContactDetailsWrapper>
            {region?.title && (
                <ContactDetailsSection>
                    <ContactDetailsSectionLabel>Ihr Ansprechpartner für diese Region:</ContactDetailsSectionLabel>
                    <ContactDetailsSectionText>{region.title}</ContactDetailsSectionText>
                </ContactDetailsSection>
            )}
            {region?.field_email && (
                <ContactDetailsSection>
                    <ContactDetailsSectionLabel>E-Mail:</ContactDetailsSectionLabel>
                    <ContactDetailsSectionText isEmail={true}>
                        <a href={`mailto:${region.field_email}`}>{region.field_email}</a>
                    </ContactDetailsSectionText>
                </ContactDetailsSection>
            )}
            {region?.field_phone && (
                <ContactDetailsSection>
                    <ContactDetailsSectionLabel>Telefon:</ContactDetailsSectionLabel>
                    <ContactDetailsSectionText>
                        <a href={`tel:${region.field_phone}`}>{region.field_phone}</a>
                    </ContactDetailsSectionText>
                </ContactDetailsSection>
            )}
        </ContactDetailsWrapper>
    );

    if (typeof window !== 'undefined') {
        return (
            <Layout title={region?.title}>
                <Alternate languages={languages} />
                <Header item={`Ihr BOGE Druckluftexperte in ${region?.field_location}`} />
                <RegionContainer>
                    <RegionTitle>{`Ihr BOGE Druckluftexperte in ${region?.field_location}`}</RegionTitle>
                    <RegionDescription>
                        Sie wünschen eine unverbindliche Beratung zum Thema Druckluft oder planen den Kauf eines
                        Kompressors? Dann kontaktieren Sie mich gerne telefonisch oder per Mail. Gerne beantworte ich
                        Ihnen alle Fragen rund um unsere Schrauben-, Kolben-, Scroll- oder Turbokompressoren oder berate
                        Sie zum Thema Ersatzteile oder Serviceangebote.
                    </RegionDescription>
                    <ContactWrapper>
                        <ContactImage
                            src={
                                region?.field_image?.length > 0 && region.field_image[0]?.field_media_image
                                    ? urlForImage(region.field_image[0].field_media_image)
                                    : placeHolder
                            }
                            alt={region?.title ?? 'contact person image'}
                        />
                        {displayContactDetails()}
                    </ContactWrapper>
                </RegionContainer>
            </Layout>
        );
    }
    return null;
};

export default Region;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
